export const pubs = [
  { id: 1, year: "2023", item: [
      {id: 1,   title: "A Late Diagnosis of Visceral Leishmaniasis Using Tru-Cut Biopsy of the Spleen and Malaria Co-Infection – A Diagnostic Challenge: A Case Report in Somalia", doi: "https://doi.org/10.2147/IDR.S420832"},
    ]},
    { id: 2, year: "2024", item: [
      {id: 1,   title: "Knowledge and practice of mother kangaroo care among nurses in public hospitals in Somalia", doi: "https://doi.org/10.1016/j.jnn.2023.12.003"  },
      {id: 2,   title: "Obstetric transition: trends in maternal mortality in Somalia over the last two decades", doi: "https://doi.org/10.1093/inthealth/ihad121" },
      {id: 3,   title: "Knowledge, Attitude and Practice of Toothbrush Contamination and Disinfection Among Undergraduate Students in Selected Universities in Somalia", doi: "https://doi.org/10.2147/CCIDE.S448793" },
      {id: 4,   title: "A team of Benadir University lead by the rector and director of Innovation published a paper on SDGs", doi: "https://doi.org/10.1186/s12889-024-18319-x" },
      {id: 5,   title: "Our experience with blast and gunshot induced traumatic vascular injuries at Somalia's major vascular referral center", doi: "https://doi.org/10.1038/s41598-024-63686-5" },
      {id: 6,   title: "Depression among general outpatient department attendees in selected hospitals in Somalia: magnitude and associated factors", doi: "https://doi.org/10.1186/s12888-024-06020-7" },
      {id: 7,   title: "Risk Factors Associated with Gastritis among Adult Patients Attending at Capital Hospital in Mogadishu- Somalia", doi: "https://doi.org/10.38124/ijisrt/IJISRT24OCT191" },
      {id: 8,   title: "Determinants of Complications of Diabetic Among Adult with Type 2 Diabetic Patients at Hubaal Specialist Hospital and Libaan Hospital in Mogadishu: Case Control Study", doi: "https://doi.org/10.11648/j.ajim.20241205.14" },
      {id: 9,   title: "Prevalence and Associated Factors of Diabetic Foot Ulcer Among Adult Diabetic Patients Attended at Madina Hospital in Mogadishu Somalia", doi: "https://doi.org/10.1186/s12888-024-06020-7" },
      {id: 10,   title: "Risk Factors Associated with Gastritis among Adult Patients Attending at Capital Hospital in Mogadishu- Somalia", doi: "https://doi.org/10.38124/ijisrt/IJISRT24OCT191" },
      {id: 11,   title: "Prevalence and Risk Factors Associated with  Benign Prostate Hyperplasia among Elderly  Patients in Baidoa Hospitals in Somalia Cross Sectional Study", doi: "https://doi.org/10.38124/ijisrt/IJISRT24OCT449" },
      {id: 12,   title: "Our experience with blast and gunshot induced traumatic vascular injuries at Somalia’s major vascular referral center", doi: "https://doi.org/10.1038/s41598-024-63686-5" },
      {id: 13,   title: "Pattern of intestinal parasitic infections in children with malnutrition in Somalia", doi: "https://doi.org/10.1016/j.ijregi.2024.100431" },
      {id: 14,   title: "Exploring the prevalence, clinical spectrum, and determinants of uncontrolled hypertension in the emergency department: Insights from a hospital-based study in Somalia", doi: "https://doi.org/10.1016/j.cpcardiol.2024.102589" },
      {id: 15,   title: "Factors associated with malnutrition among children under five years in Mogadishu, Somalia: A multicenter cross-sectional study", doi: "https://doi.org/10.21203/rs.3.rs-4223258/v1" },
      {id: 16,   title: "The Role of Civil Society on State Building in Somalia", doi: "https://doi.org/10.4236/jss.2024.1212042" },

    ]}, 
]

