import React from 'react'
import { BsFillCalendarWeekFill , BsPersonFill} from 'react-icons/bs';
import { FaCertificate } from 'react-icons/fa';
import { MdPeopleAlt } from 'react-icons/md';

const MenuOptions = () => {
  return (
    <div className=' w-full my-2 mx-auto px-4 py-5 md:flex  md:flex-row xl:flex xl:flex-row xl:py-10 grid sm:grid-cols-2 xs:grid-cols-2 justify-center md:space-x-10 flex-wrap items-center xl:space-x-24'>
        <a href='/callender' target="_blank" rel="noopener noreferrer" className='text-[#0863AA] font-semibold  flex flex-row py-4 md:px-3 justify-center items-center ' >
            <BsFillCalendarWeekFill className='  text-7xl xs:text-3xl'/>
            <p className='px-4 text-2xl xs:text-xl'>
                Calender
            </p>
        </a>

        <a href='https://students.bu.edu.so/login' target="_blank" rel="noopener noreferrer" className='text-[#0863AA] font-semibold   flex flex-row py-4 md:px-3 justify-center items-center '>
            <BsPersonFill className=' text-7xl xs:text-3xl'/>
            <p className='px-4 text-2xl  xs:text-base'>
                Student 
                <br/>
                Portal
            </p>
        </a>


        <a href='https://bu.fedena.com/' target="_blank" rel="noopener noreferrer" className='text-[#0863AA] font-semibold  flex flex-row py-4 md:px-3 justify-center items-center '>
            <MdPeopleAlt className=' text-7xl xs:text-3xl'/>
            <p className='px-4 text-2xl  xs:text-xl'>
                Fedena
            </p>

        </a>

      

        <a href='/verify' target="_blank" rel="noopener noreferrer" className='text-[#0863AA] font-semibold  flex flex-row py-4 md:px-3 justify-center items-center'>
            <FaCertificate className=' text-7xl xs:text-3xl'/>
            <p className='px-4 text-2xl  xs:text-base'>
                Certificate   <br/>
                Verification
            </p>
        </a>



    </div>
  )
}

export default MenuOptions