import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {phd} from "../../../data/programs"
import {postgradute} from "../../../data/programs"
import {PostgraduateDeploma} from "../../../data/programs"
import SelectInput from './selectInput'


const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';

const cover =  backet + '/others/cover.jpeg'

const img =  backet + '/postgrad/fuje.jpg'

const img1 =  backet + '/postgrad/programs.PNG'


const PostgradHome = () => {


  return (

    // panel
    <div className=''>
        <div className='relative w-full xl:h-[558px] md:h-[558px] group'>
          <img src={cover} className='w-full object-cover xl:h-[558px] md:h-[558px] bg-center' alt="cover"/>
          <div className='absolute top-[65%] md:top-[80%] xl:top-[80%] sm:top-[80%] xs:top-[80%]  w-full h-[15%] bg-white opacity-60 flex justify-center items-center'></div>
          <p className=' md:text-6xl xl:text-5xl xs:text-xl sm:text-5xl font-bold text-[#0843aafe] text-center  md:top-[83%] xl:top-[84%] sm:top-[82%] xs:top-[83%]  absolute w-full uppercase'> Postgraduate PROGRAMS</p>
        </div>
        <div className="h-full flex flex-col overflow-hidden xl:mx-[200px] md:mx-[200px] space-y-2 my-5 mb-16 text-black mx-5">
            <div className='space-y-4'>
                <p className='text-xl font-semibold my-5'>School of Postgraduate Studies</p>
                <p className='leading-7 -tracking-normal text-xl my-2 text-justify '>Our postgraduate programmes aim to stimulate interactions between academicians, students and the industry, where world-changing ideas can be formulated, and global discussions can take place within the campus. Our School of Postgraduate Studies houses a variety of facilities and spaces for you to lear, engage and interact</p>
            </div>
            <div className='grid md:grid-cols-3 xl:grid-cols-3 grid-col-1 gap-8 justify-center md:items-start items-center place-items-center  my-5 '>
                <div className='col-span-2'>
                    <p className='text-xl font-semibold my-5 mt-10'>Dean's Message</p>
                    <p className='leading-7 -tracking-normal text-xl my-2  text-justify'>
                    It is with great pleasure that I welcome you all to the School of Postgraduate Studies of
                    Benadir University, the leading university in Somalia. Since its beginning, the school has demonstrated excellence in its performance and achieved very strong milestones. We are imevocably committed to sustaining this culture and developing the school further to regional and international standards.

                    Our goal is to deliver quality education at higher levels in different fields. We provide you with
                    the opportunity to grow and increase your knowledge through joint programmes with the
                    International University of Africa (Sudan), Makerere University, Istanbul Madeniyet University,
                    ‘and many more that are in the pipeline.

                    Through our postgraduate programmes, we want to produce highly skilled leaders who have
                    undergone intensive, quality training and provisional courses.

                    | hereby welcome you once again to the school of postgraduate studies and wish you
                    remarkable success in your academic endeavours.

                    Best wishes for you all!
                    </p>


                </div>
                <div className='col-span-1'>
                    <img src={img} alt="dean-avatar" className='w-full' />

                </div>
            </div>
            <div className='w-full flex justify-center items-center '>
            <img src={img1} alt="dean-avatar" className='w-[250px] h-[200px]' />
            </div>
            <div className=''>
         
            <Tabs className="" defaultIndex={1}>
            <TabList className="flex flex-row font-bold bg-[#E8EDED] w-fit border-b-2 border-[#0863AA]">
              <Tab  selectedClassName="bg-[#0863AA] text-white" className="px-10 py-2 text-xl text-[#0863AA] hover:cursor-pointer hover:bg-[#0863AA] hover:text-white">Phd Programs</Tab>
              <Tab  selectedClassName="bg-[#0863AA] text-white" className="px-10 py-2 text-xl text-[#0863AA] hover:cursor-pointer hover:bg-[#0863AA] hover:text-white">Masters Programs</Tab>
              <Tab selectedClassName="bg-[#0863AA] text-white" className="px-10 py-2 text-xl text-[#0863AA] hover:cursor-pointer hover:bg-[#0863AA] hover:text-white">Diploma Programs</Tab>
            </TabList>
            <TabPanel>
              <SelectInput schools={phd}/>
            </TabPanel>
            <TabPanel>
              <SelectInput schools={postgradute}/>
            </TabPanel>
            <TabPanel>
              <SelectInput schools={PostgraduateDeploma}/>
            </TabPanel>
        </Tabs>

            </div>
            
        </div>
    </div>
  )
}

export default PostgradHome;